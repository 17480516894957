export const formatCurrencyToString = (value: number) => {
  if (!value) return `$0`
  switch (true) {
    case value >= 1000 && value < 1000000:
      return `$${(value / 1000).toFixed(2)}K`
    case value >= 1000000 && value < 1000000000:
      return `$${(value / 1000000).toFixed(2)}M`
    case value >= 1000000000:
      return `$${(value / 1000000000).toFixed(2)}B`
    default:
      return `${value.toFixed(2)}`
  }
}
export const formatNumberToString = (value: number) => {
  if (!value) return 0
  switch (true) {
    case value >= 1000 && value < 1000000:
      return `${(value / 1000).toFixed(2)}K`
    case value >= 1000000 && value < 1000000000:
      return `${(value / 1000000).toFixed(2)}M`
    case value >= 1000000000:
      return `${(value / 1000000000).toFixed(2)}B`
    default:
      return `${value.toFixed(2)}`
  }
}
