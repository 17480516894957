/*
 * Mapping between address and CoinGecko coin id
 * Using CoinGecko API: https://api.coingecko.com/api/v3/coins/list
 */
const UNFORMATTED_COIN_ID_MAP = {
  // AB Tokenlist
  '0x60781C2586D68229fde47564546784ab3fACA982': 'pangolin', // PNG
  '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7': 'avalanche', // WAVAX
  '0xd501281565bf7789224523144Fe5D98e8B28f267': '1inch', // 1INCH.e
  '0x63a72806098Bd3D9520cC43356dD78afe5D386D9': 'aave', // AAVE.e
  '0x98443B96EA4b0858FDF3219Cd13e98C7A4690588': 'basic-attention-token', // BAT.e
  '0x19860CCB0A68fd4213aB9D8266F7bBf05A8dDe98': 'binance-usd', // BUSD.e
  '0xc3048E19E76CB9a3Aa9d77D8C03c29Fc906e2437': 'compound', // COMP.e
  '0x249848BeCA43aC405b8102Ec90Dd5F22CA513c06': 'curve-dao-token', // CRV.e
  '0xd586E7F844cEa2F87f50152665BCbc2C279D8d70': 'dai', // DAI.e
  '0x8a0cAc13c7da965a312f08ea4229c37869e85cB9': 'the-graph', // GRT.e
  '0x5947BB275c521040051D82396192181b413227A3': 'chainlink', // LINK.e
  '0x88128fd4b259552A9A1D457f435a6527AAb72d42': 'maker', // MKR.e
  '0xBeC243C995409E6520D7C41E404da5dEba4b209B': 'synthetix-network-token', // SNX.e
  '0x37B608519F91f70F2EeB0e5Ed9AF4061722e4F76': 'sushi', // SUSHI.e
  '0xc7B5D72C836e718cDA8888eaf03707fAef675079': 'trustswap', // SWAP.e
  '0x3Bd2B1c7ED8D396dbb98DED3aEbb41350a5b2339': 'uma', // UMA.e
  '0x8eBAf22B6F053dFFeaf46f4Dd9eFA95D89ba8580': 'uniswap', // UNI.e
  '0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664': 'usd-coin', // USDC.e
  '0xc7198437980c041c805A1EDcbA50c1Ce5db95118': 'tether', // USDT.e
  '0x50b7545627a5162F82A992c33b87aDc75187B218': 'bitcoin', // WBTC.e
  '0x49D5c2BdFfac6CE2BFdB6640F4F80f226bc10bAB': 'ethereum', // WETH.e
  '0x9eAaC1B23d935365bD7b542Fe22cEEe2922f52dc': 'yearn-finance', // YFI.e
  '0x596fA47043f99A4e0F122243B841E55375cdE0d2': '0x', // ZRX.e

  // Defi Tokenlist
  '0x78ea17559B3D2CF85a7F9C2C704eda119Db5E6dE': 'avaware', // AVE
  '0xdb333724fAE72b4253FC3d44c8270CBBC86d147b': undefined, // CABAG
  '0x3711c397B6c8F7173391361e27e67d72F252cAad': 'complus-network', // COM
  '0x488F73cddDA1DE3664775fFd91623637383D6404': 'yetiswap', // YTS
  '0x008E26068B3EB40B443d3Ea88c1fF99B789c10F7': 'zero-exchange', // ZERO
  '0xC38f41A296A4493Ff429F1238e030924A1542e50': 'snowball-token', // SNOB
  '0x1F1FE1eF06ab30a791d6357FdF0a7361B39b1537': undefined, // SFI
  '0x6e7f5C0b9f4432716bDd0a77a3601291b9D9e985': 'spore', // SPORE
  '0xe896CDeaAC9615145c0cA09C8Cd5C25bced6384c': 'penguin-finance', // PEFI
  '0xC931f61B1534EB21D8c11B24f3f5Ab2471d4aB50': 'any-blocknet', // aaBLOCK
  '0x4C9B4E1AC6F24CdE3660D5E4Ef1eBF77C710C084': 'lydia-finance', // LYD
  '0x846D50248BAf8b7ceAA9d9B53BFd12d7D7FBB25a': 'verso', // VSO
  '0x1ECd47FF4d9598f89721A2866BFEb99505a413Ed': 'avme', // AVME
  '0xE9D00cBC5f02614d7281D742E6E815A47ce31107': undefined, // CRACK
  '0x65378b697853568dA9ff8EaB60C13E1Ee9f4a654': 'husky-avax', // HUSKY
  '0xD606199557c8Ab6F4Cc70bD03FaCc96ca576f142': 'gondola-finance', // GDL
  '0x81440C939f2C1E34fc7048E518a637205A632a74': 'cycle-token', // CYCLE
  '0xd1c3f94DE7e5B45fa4eDBBA472491a9f4B166FC4': 'avalaunch', // XAVA
  '0x8349088C575cA45f5A63947FEAeaEcC41136fA01': undefined, // TESLABTC
  '0x4aBBc3275f8419685657C2DD69b8ca2e26F23F8E': undefined, // Diamond
  '0x76076880e1EBBcE597e6E15c47386cd34de4930F': 'canopus', // OPUS
  '0x8D88e48465F30Acfb8daC0b3E35c9D6D7d36abaf': 'canary', // CNR
  '0xa5E59761eBD4436fa4d20E1A27cBa29FB2471Fc6': 'sherpa', // SHERPA
  '0x961C8c0B1aaD0c0b10a51FeF6a867E3091BCef17': 'defi-yield-protocol', // DYP
  '0xd6070ae98b8069de6B494332d1A1a81B6179D960': 'beefy-finance', // BIFI
  '0x264c1383EA520f73dd837F915ef3a732e204a493': 'binance-coin', // BNB
  '0xB1466d4cf0DCfC0bCdDcf3500F473cdACb88b56D': 'weble-ecosystem-token', // WET
  '0x59414b3089ce2AF0010e7523Dea7E2b35d776ec7': 'yield-yak', // YAK
  '0x8729438EB15e2C8B576fCc6AeCdA6A148776C0F5': 'benqi', // QI
  '0x9E037dE681CaFA6E661e6108eD9c2bd1AA567Ecd': 'allianceblock', // WALBT
  '0x21c5402C3B7d40C89Cc472C9dF5dD7E51BbAb1b1': 'tundra-token', // TUNDRA
  '0x595c8481c48894771CE8FaDE54ac6Bf59093F9E8': 'gaj', // GAJ
  '0x094bd7B2D99711A1486FB94d4395801C6d0fdDcC': 'teddy-cash', // TEDDY
  '0x6e84a6216eA6dACC71eE8E6b0a5B7322EEbC0fDd': 'joe', // JOE
  '0xE1C110E1B1b4A1deD0cAf3E42BfBdbB7b5d7cE1C': 'elk-finance', // ELK
  '0x9Fda7cEeC4c18008096C2fE2B85F05dc300F94d0': 'marginswap', // MFI
  '0xAcD7B3D9c10e97d0efA418903C0c7669E702E4C0': 'eleven-finance', // ELE
  '0x440aBbf18c54b2782A4917b80a1746d3A2c2Cce1': 'shibavax', // SHIBX
  '0x9eF758aC000a354479e538B8b2f01b917b8e89e7': 'xdollar', // XDO
  '0xDd453dBD253fA4E5e745047d93667Ce9DA93bbCF': 'zabu-token', // ZABU
  '0xD67de0e0a0Fd7b15dC8348Bb9BE742F3c5850454': 'frax-share', // FXS
  '0xF44Fb887334Fa17d2c5c0F970B5D320ab53eD557': 'starter-xyz', // START
  '0x62a4f3280C02C8Cc3E9ff984e4aaD94f8F7fEA26': undefined, // BABYPangolin
  '0xc12e249FaBe1c5Eb7C558E5F50D187687a244E31': undefined, // BLUE
  '0x999c891262ce01f1C1AFD1D46260E4c1E508B243': undefined, // GIVE
  '0x6AFD5A1ea4b793CC1526d6Dc7e99A608b356eF7b': 'storm-token', // STORM
  '0xf57b80A574297892B64E9a6c997662889b04a73a': undefined, // EXP
  '0x8A9B36393633aD77ceb8aebC7768815627B93557': undefined, // SPHERE.e
  '0x01C2086faCFD7aA38f69A6Bd8C91BEF3BB5adFCa': 'yay-games', // YAY
  '0x397bBd6A0E41bdF4C3F971731E180Db8Ad06eBc1': 'avaxtars-token', // AVXT
  '0xae9d2385Ff2E2951Dd4fA061e74c4d3deDD24347': undefined, // TOK
  '0xb54f16fB19478766A268F172C9480f8da1a7c9C3': undefined, // TIME
  '0x90842eb834cFD2A1DB0b1512B254a18E4D396215': 'good-bridging', // GB
  '0x0ebd9537A25f56713E34c45b38F421A1e7191469': 'openocean', // OOE
  '0x3709E8615E02C15B096f8a9B460ccb8cA8194e86': 'vee-finance', // VEE
  '0x938FE3788222A74924E062120E7BFac829c719Fb': undefined, // APEIN
  '0xbe6D6323eA233fD1DBe1fF66c5252170c69fb6c7': undefined, // ZUBAX
  '0x69A61f38Df59CBB51962E69C54D39184E21C27Ec': undefined, // PARTY
  '0xd039C9079ca7F2a87D632A9C0d7cEa0137bAcFB5': 'ape-x', // APE-X

  '0x61eCd63e42C27415696e10864d70ecEA4aA11289': 'rugpull-prevention', // RUGPULL
}

// Ensure all address keys are lowercase
export const COIN_ID_MAP = Object.entries(UNFORMATTED_COIN_ID_MAP).reduce(
  (map, [address, id]) => ({ ...map, [address.toLowerCase()]: id }),
  {}
)
