import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { Text } from 'rebass'
import 'feather-icons'
import styled from 'styled-components'
import Panel from '../components/Panel'
import { PageWrapper, ContentWrapperLarge, StyledIcon } from '../components/index'
import { AutoRow, RowBetween, RowFixed } from '../components/Row'
import Column, { AutoColumn } from '../components/Column'
import { ButtonLight, ButtonDark, ButtonAdd, ButtonLinear } from '../components/ButtonStyled'
import PairChart from '../components/PairChart'
import loadingIcon from 'assets/images/icons/loading.png'
import Link from '../components/Link'
import TxnList from '../components/TxnList'
import Loader from '../components/LocalLoader'
import { BasicLink } from '../components/Link'
import Search from '../components/Search'
import { formattedNum, formattedPercent, getPoolLink, getSwapLink } from '../utils'
import { useColor } from '../hooks'
import { usePairData, usePairTransactions } from '../contexts/PairData'
import {
  TYPE,
  ThemedBackground,
  FlexItemCenter,
  TextInternal,
  Icon,
  IconsBreadcrumb,
  LabelStyled,
  RadioStyled,
  TextPurple,
  TextCurrency,
  FlexBox,
  TextSymbol,
  TextToken,
} from '../Theme'
import { transparentize } from 'polished'
import CopyHelper from '../components/Copy'
import { useMedia } from 'react-use'
import DoubleTokenLogo from '../components/DoubleLogo'
import TokenLogo from '../components/TokenLogo'
import { Hover } from '../components'
import { useEthPrice } from '../contexts/GlobalData'
import { ArbitraryWarning, MigrateWarning } from '../components/Warning'
import { usePathDismissed, useSavedPairs, useSavedTokens } from '../contexts/LocalStorage'

import { Bookmark, PlusCircle } from 'react-feather'
import FormattedName from '../components/FormattedName'
import { useListedTokens } from '../contexts/Application'
import { AEB_TOKEN_ADDRESSES, SWAP_FEE_TO_LP, TXN_TYPE } from '../constants'
import { formatCurrencyToString, formatNumberToString } from '../utils/formatNumber'
import './index.css'
const DashboardWrapper = styled.div`
  width: 100%;
`

const PanelWrapper = styled.div`
  /* grid-template-columns: repeat(3, 1fr);
  grid-template-rows: max-content; */
  gap: 6px;
  display: grid;
  width: 100%;
  align-items: start;
  @media screen and (max-width: 1024px) {
    width: 100%;
    grid-template-columns: 1fr;
    align-items: stretch;
    > * {
      grid-column: 1 / 4;
    }

    > * {
      &:first-child {
        width: 100%;
      }
    }
  }
`

// const TokenDetailsLayout = styled.div`
//   display: inline-grid;
//   width: 100%;
//   grid-template-columns: auto auto auto auto 1fr;
//   column-gap: 60px;
//   align-items: start;

//   &:last-child {
//     align-items: center;
//     justify-items: end;
//   }
//   @media screen and (max-width: 1024px) {
//     grid-template-columns: 1fr;
//     align-items: stretch;
//     > * {
//       grid-column: 1 / 4;
//       margin-bottom: 1rem;
//     }

//     &:last-child {
//       align-items: start;
//       justify-items: start;
//     }
//   }
// `

const FixedPanel = styled(Panel)`
  width: fit-content;
  padding: 8px 12px;
  border-radius: 10px;

  :hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.bg2};
  }
`
const BoxBlack = styled.div`
  border: 1px solid rgb(56, 50, 65);
  background-color: rgb(8, 6, 11);
  padding: 16px;
  flex-direction: column;
  gap: 8px;
  width: 80%;
  margin-top: 8px;
  border-radius: 16px;
  max-width: 280px;
`

// const HoverSpan = styled.span`
//   :hover {
//     cursor: pointer;
//     opacity: 0.7;
//   }
// `

const WarningGrouping = styled.div`
  opacity: ${({ disabled }) => disabled && '0.4'};
  pointer-events: ${({ disabled }) => disabled && 'none'};
`

function PairPage({ pairAddress, history }) {
  const {
    token0,
    token1,
    reserve0,
    reserve1,
    reserveUSD,
    trackedReserveUSD,
    oneDayVolumeUSD,
    volumeChangeUSD,
    oneDayVolumeUntracked,
    volumeChangeUntracked,
    liquidityChangeUSD,
  } = usePairData(pairAddress)

  const [txFilter, setTxFilter] = useState(TXN_TYPE.ALL)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    document.querySelector('body').scrollTo(0, 0)
  }, [])

  const transactions = usePairTransactions(pairAddress)
  const backgroundColor = useColor(pairAddress)

  // liquidity
  const liquidity = trackedReserveUSD
    ? formattedNum(trackedReserveUSD, true)
    : reserveUSD
    ? formattedNum(reserveUSD, true)
    : '-'
  const liquidityChange = formattedPercent(liquidityChangeUSD)

  // mark if using untracked liquidity
  const [usingTracked, setUsingTracked] = useState(true)
  useEffect(() => {
    setUsingTracked(!trackedReserveUSD ? false : true)
  }, [trackedReserveUSD])

  // volume	  // volume
  const volume =
    oneDayVolumeUSD || oneDayVolumeUSD === 0
      ? formattedNum(oneDayVolumeUSD === 0 ? oneDayVolumeUntracked : oneDayVolumeUSD, true)
      : oneDayVolumeUSD === 0
      ? '$0'
      : '-'

  // mark if using untracked volume
  const [usingUtVolume, setUsingUtVolume] = useState(false)
  useEffect(() => {
    setUsingUtVolume(oneDayVolumeUSD === 0)
  }, [oneDayVolumeUSD])

  const volumeChange = formattedPercent(!usingUtVolume ? volumeChangeUSD : volumeChangeUntracked)

  // get fees
  const fees =
    oneDayVolumeUSD || oneDayVolumeUSD === 0
      ? usingUtVolume
        ? formattedNum(oneDayVolumeUntracked * SWAP_FEE_TO_LP, true)
        : formattedNum(oneDayVolumeUSD * SWAP_FEE_TO_LP, true)
      : '-'

  // token data for usd
  const [ethPrice] = useEthPrice()
  const token0USD =
    token0?.derivedETH && ethPrice ? formattedNum(parseFloat(token0.derivedETH) * parseFloat(ethPrice), true) : ''

  const token1USD =
    token1?.derivedETH && ethPrice ? formattedNum(parseFloat(token1.derivedETH) * parseFloat(ethPrice), true) : ''

  // rates
  const token0Rate = reserve0 && reserve1 ? formattedNum(reserve1 / reserve0) : '-'
  const token1Rate = reserve0 && reserve1 ? formattedNum(reserve0 / reserve1) : '-'

  // formatted symbols for overflow
  const formattedSymbol0 = token0?.symbol.length > 6 ? token0?.symbol.slice(0, 5) + '...' : token0?.symbol
  const formattedSymbol1 = token1?.symbol.length > 6 ? token1?.symbol.slice(0, 5) + '...' : token1?.symbol

  const below1080 = useMedia('(max-width: 1080px)')
  const below900 = useMedia('(max-width: 900px)')
  const below600 = useMedia('(max-width: 600px)')

  const [dismissed, markAsDismissed] = usePathDismissed(history.location.pathname)

  useEffect(() => {
    window.scrollTo({
      behavior: 'smooth',
      top: 0,
    })
    setLoading(false)
  }, [])

  const [savedPairs, addPair, removePair] = useSavedPairs()

  const listedTokens = useListedTokens()

  if (loading) {
    return <img src={loadingIcon} alt="Loading" className="loading-icon" />
  }
  const RadioGroup = () => {
    return (
      <RowFixed area="txn" width="100% !important" gap="10px" pl={4} margin="24px 0">
        <LabelStyled
          onClick={() => {
            setTxFilter(TXN_TYPE.ALL)
          }}
        >
          <RadioStyled type="radio" checked={txFilter === TXN_TYPE.ALL} />
          All
        </LabelStyled>
        <LabelStyled
          onClick={() => {
            setTxFilter(TXN_TYPE.SWAP)
          }}
        >
          <RadioStyled type="radio" checked={txFilter === TXN_TYPE.SWAP} />
          Swaps
        </LabelStyled>
        <LabelStyled
          onClick={() => {
            setTxFilter(TXN_TYPE.ADD)
          }}
        >
          <RadioStyled type="radio" checked={txFilter === TXN_TYPE.ADD} />
          Adds
        </LabelStyled>
        <LabelStyled
          onClick={() => {
            setTxFilter(TXN_TYPE.REMOVE)
          }}
        >
          <RadioStyled type="radio" checked={txFilter === TXN_TYPE.REMOVE} />
          Removes
        </LabelStyled>
      </RowFixed>
    )
  }

  return (
    <PageWrapper>
      <ThemedBackground backgroundColor={transparentize(0.6, backgroundColor)} />

      <ArbitraryWarning
        type={'pair'}
        show={!dismissed && listedTokens && !(listedTokens.includes(token0?.id) && listedTokens.includes(token1?.id))}
        setShow={markAsDismissed}
        address={pairAddress}
      />
      <MigrateWarning show={AEB_TOKEN_ADDRESSES.includes(token0?.id) || AEB_TOKEN_ADDRESSES.includes(token1?.id)} />

      <ContentWrapperLarge>
        <RowBetween style={{ flexWrap: 'wrap', alingItems: 'start' }}>
          <AutoRow align="flex-end" style={{ width: 'fit-content' }}>
            <TYPE.body style={{ display: 'flex', alignItems: 'center' }}>
              <BasicLink to="/pairs">{'Pairs '}</BasicLink>
              <FlexItemCenter>
                <IconsBreadcrumb src="/icons/inext.svg" />
                <span>
                  {token0?.symbol} / {token1?.symbol}{' '}
                </span>
              </FlexItemCenter>
            </TYPE.body>
          </AutoRow>
          <FlexItemCenter>
            <Link
              style={{ width: 'fit-content' }}
              color={backgroundColor}
              external
              href={'https://snowtrace.io/address/' + pairAddress}
            >
              <FlexItemCenter style={{ marginRight: '10px' }}>
                <TextInternal>View on explorer</TextInternal>
                <Icon src="/icons/iextend.svg" />
              </FlexItemCenter>
            </Link>
            {!!savedPairs[pairAddress] ? (
              <Icon src={'/icons/istar-yellow.svg'} onClick={() => removePair(pairAddress)} />
            ) : (
              <Icon
                src={'/icons/istar.svg'}
                onClick={() => addPair(pairAddress, token0.id, token1.id, token0.symbol, token1.symbol)}
              />
            )}
          </FlexItemCenter>
          {/* {!below600 && <Search small={true} />} */}
        </RowBetween>

        <WarningGrouping
          disabled={
            !dismissed && listedTokens && !(listedTokens.includes(token0?.id) && listedTokens.includes(token1?.id))
          }
        >
          <DashboardWrapper>
            <AutoColumn gap="40px" style={{ marginBottom: '1.5rem' }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexWrap: 'wrap',
                  width: '100%',
                }}
              >
                <RowFixed style={{ flexWrap: 'wrap', minWidth: '100px' }}>
                  <RowFixed>
                    {token0 && token1 && (
                      <DoubleTokenLogo a0={token0?.id || ''} a1={token1?.id || ''} size={32} margin={true} />
                    )}
                    <TYPE.main fontSize={below1080 ? '1.5rem' : '2rem'} style={{ margin: '0 1rem' }}>
                      {token0 && token1 ? (
                        <>
                          <FormattedName
                            text={`${token0.symbol} / ${token1.symbol}`}
                            maxCharacters={16}
                            style={{ marginRight: '6px' }}
                            fontSize="40px"
                            fontWeight="600"
                          />
                          {/* <HoverSpan onClick={() => history.push(`/token/${token0?.id}`)}>{token0.symbol}</HoverSpan>
                          <span>/</span>
                          <HoverSpan onClick={() => history.push(`/token/${token1?.id}`)}>{token1.symbol}</HoverSpan> */}
                        </>
                      ) : (
                        ''
                      )}
                    </TYPE.main>
                  </RowFixed>
                </RowFixed>
                <RowFixed
                  ml={below900 ? '0' : '2.5rem'}
                  mt={below1080 && '1rem'}
                  style={{
                    flexDirection: below1080 ? 'row-reverse' : 'initial',
                  }}
                >
                  {/* {!!!savedPairs[pairAddress] && !below1080 ? (
                    <Hover onClick={() => addPair(pairAddress, token0.id, token1.id, token0.symbol, token1.symbol)}>
                      <StyledIcon>
                        <PlusCircle style={{ marginRight: '0.5rem' }} />
                      </StyledIcon>
                    </Hover>
                  ) : !below1080 ? (
                    <StyledIcon>
                      <Bookmark style={{ marginRight: '0.5rem', opacity: 0.4 }} />
                    </StyledIcon>
                  ) : (
                    <></>
                  )} */}

                  <Link href={`${process.env.REACT_APP_GEMUNI_URL}/liquidity`} target="_blank">
                    <ButtonAdd style={{ marginLeft: '10px' }}>Add Liquidity</ButtonAdd>
                  </Link>
                  <Link href={process.env.REACT_APP_GEMUNI_URL} target="_blank">
                    <ButtonLinear>Trade</ButtonLinear>
                  </Link>
                </RowFixed>
              </div>
            </AutoColumn>
            <AutoRow
              gap="6px"
              style={{
                width: 'fit-content',
                alignItems: below600 ? 'flex-start' : 'center',
                flexDirection: below600 ? 'column' : 'row',
                marginTop: below900 ? '1rem' : '0',
                marginBottom: 0,
                flexWrap: 'wrap',
              }}
            >
              <FixedPanel
                onClick={() => history.push(`/token/${token0?.id}`)}
                style={{ border: 'none', background: 'none' }}
              >
                <RowFixed>
                  <TokenLogo address={token0?.id} size={'24px'} />
                  <TYPE.main fontSize={'16px'} lineHeight={1} fontWeight={500} ml={'4px'}>
                    {token0 && token1
                      ? `1 ${formattedSymbol0} = ${token0Rate} ${formattedSymbol1} ${
                          parseFloat(token0?.derivedETH) ? '(' + token0USD + ')' : ''
                        }`
                      : '-'}
                  </TYPE.main>
                </RowFixed>
              </FixedPanel>
              <FixedPanel
                onClick={() => history.push(`/token/${token1?.id}`)}
                style={{ border: 'none', background: 'none' }}
              >
                <RowFixed>
                  <TokenLogo address={token1?.id} size={'24px'} />
                  <TYPE.main fontSize={'16px'} lineHeight={1} fontWeight={500} ml={'4px'}>
                    {token0 && token1 ? `1 ${formattedSymbol1} = ${token1Rate} ${formattedSymbol0}` : '-'}
                  </TYPE.main>
                </RowFixed>
              </FixedPanel>
            </AutoRow>
            <>
              {/* {!below1080 && <TYPE.main fontSize={'1.125rem'}>Pair Stats</TYPE.main>} */}
              <FlexBox style={{ alignItems: 'flex-start', marginTop: '20px' }}>
                <FlexBox style={{ flexDirection: 'column', width: below600 ? '100%' : '22%' }}>
                  <PanelWrapper
                    style={{
                      // marginTop: below1080 ? '0' : '1.5rem',
                      background: 'rgba(43, 27, 78, 0.4)',
                      borderRadius: '24px',
                      border: '1px solid rgba(43, 27, 78, 0.4)',
                      padding: below600 ? 0 : '24px',
                    }}
                  >
                    <Panel style={{ border: 'none', background: 'none', padding: below600 ? '12px' : 0 }}>
                      <AutoColumn gap="8px">
                        <RowBetween>
                          <TextPurple>LIQUIDITY</TextPurple>
                        </RowBetween>
                        <RowBetween>
                          <TextCurrency>{formatCurrencyToString(trackedReserveUSD ?? reserveUSD)}</TextCurrency>
                        </RowBetween>
                        <RowBetween>
                          <TYPE.main>{liquidityChange}</TYPE.main>
                        </RowBetween>
                      </AutoColumn>
                    </Panel>
                    <Panel style={{ border: 'none', background: 'none', padding: below600 ? '12px' : 0 }}>
                      <AutoColumn gap="8px">
                        <RowBetween>
                          <TextPurple>TOTAL TOKENS LOCKED</TextPurple>
                        </RowBetween>
                        <RowBetween style={{ justifyContent: 'center' }}>
                          <BoxBlack>
                            <AutoRow gap="4px" style={{ justifyContent: 'space-between' }}>
                              <FlexBox>
                                <TokenLogo address={token0?.id} />
                                <TextSymbol>{token0?.symbol ?? ''}</TextSymbol>
                              </FlexBox>
                              <TextToken>{formatNumberToString(reserve0 ?? 0)}</TextToken>
                            </AutoRow>
                            <AutoRow gap="4px" style={{ justifyContent: 'space-between', marginTop: '5px' }}>
                              <FlexBox>
                                <TokenLogo address={token1?.id} />
                                <TextSymbol>{token1?.symbol ?? ''}</TextSymbol>
                              </FlexBox>
                              <TextToken>{formatNumberToString(reserve1 ?? 0)}</TextToken>
                            </AutoRow>
                          </BoxBlack>
                        </RowBetween>
                        {/* <RowBetween>
                        <TYPE.main>{liquidityChange}</TYPE.main>
                      </RowBetween> */}
                      </AutoColumn>
                    </Panel>

                    {/* <Panel style={{ border: 'none', background: 'none', padding: below600 ? '12px' : 0 }}>
                    <AutoColumn gap="20px">
                      <RowBetween>
                        <TYPE.main>Pooled Tokens</TYPE.main>
                        <div />
                      </RowBetween>
                      <Hover onClick={() => history.push(`/token/${token0?.id}`)} fade={true}>
                        <AutoRow gap="4px">
                          <TokenLogo address={token0?.id} />
                          <TYPE.main fontSize={20} lineHeight={1} fontWeight={500}>
                            <RowFixed>
                              {reserve0 ? formattedNum(reserve0) : ''}{' '}
                              <FormattedName text={token0?.symbol ?? ''} maxCharacters={8} margin={true} />
                            </RowFixed>
                          </TYPE.main>
                        </AutoRow>
                      </Hover>
                      <Hover onClick={() => history.push(`/token/${token1?.id}`)} fade={true}>
                        <AutoRow gap="4px">
                          <TokenLogo address={token1?.id} />
                          <TYPE.main fontSize={20} lineHeight={1} fontWeight={500}>
                            <RowFixed>
                              {reserve1 ? formattedNum(reserve1) : ''}{' '}
                              <FormattedName text={token1?.symbol ?? ''} maxCharacters={8} margin={true} />
                            </RowFixed>
                          </TYPE.main>
                        </AutoRow>
                      </Hover>
                    </AutoColumn>
                  </Panel> */}
                  </PanelWrapper>
                  <PanelWrapper
                    style={{
                      // marginTop: below1080 ? '0' : '1.5rem',
                      background: 'rgba(43, 27, 78, 0.4)',
                      borderRadius: '24px',
                      border: '1px solid rgba(43, 27, 78, 0.4)',
                      padding: below600 ? 0 : '24px',
                      marginTop: '22px',
                    }}
                  >
                    <Panel style={{ border: 'none', background: 'none', padding: below600 ? '12px' : 0 }}>
                      <AutoColumn gap="8px">
                        <RowBetween>
                          <TextPurple>VOLUME 24H</TextPurple>
                        </RowBetween>
                        <RowBetween>
                          <TextCurrency>
                            {formatCurrencyToString(oneDayVolumeUSD === 0 ? oneDayVolumeUntracked : oneDayVolumeUSD)}
                          </TextCurrency>
                        </RowBetween>
                        <RowBetween>
                          <TYPE.main>{volumeChange}</TYPE.main>
                        </RowBetween>
                      </AutoColumn>
                    </Panel>
                  </PanelWrapper>
                </FlexBox>
                <Panel
                  style={{
                    padding: '1.125rem',
                    width: below600 ? '100%' : '72%',
                    minHeight: '427px',
                    background: 'rgba(43, 27, 78, 0.4)',
                    borderRadius: '24px',
                    border: '1px solid rgba(43, 27, 78, 0.4)',
                    marginTop: below600 ? '20px' : '0',
                  }}
                >
                  <PairChart
                    address={pairAddress}
                    color={backgroundColor}
                    base0={reserve1 / reserve0}
                    base1={reserve0 / reserve1}
                  />
                </Panel>
              </FlexBox>
              <TYPE.main fontSize={'1.125rem'} style={{ marginTop: '3rem' }}>
                Transactions
              </TYPE.main>
              <RadioGroup />
              <Panel
                style={{
                  marginTop: '1.5rem',
                }}
              >
                {transactions ? <TxnList transactions={transactions} txFilterTrans={txFilter} /> : <Loader />}
              </Panel>
              {/* <RowBetween style={{ marginTop: '3rem' }}>
                <TYPE.main fontSize={'1.125rem'}>Pair Information</TYPE.main>{' '}
              </RowBetween>
              <Panel
                rounded
                style={{
                  marginTop: '1.5rem',
                }}
                p={20}
              >
                <TokenDetailsLayout>
                  <Column>
                    <TYPE.main>Pair Name</TYPE.main>
                    <TYPE.main style={{ marginTop: '.5rem' }}>
                      <RowFixed>
                        <FormattedName text={token0?.symbol ?? ''} maxCharacters={8} />
                        -
                        <FormattedName text={token1?.symbol ?? ''} maxCharacters={8} />
                      </RowFixed>
                    </TYPE.main>
                  </Column>
                  <Column>
                    <TYPE.main>Pair Address</TYPE.main>
                    <AutoRow align="flex-end">
                      <TYPE.main style={{ marginTop: '.5rem' }}>
                        {pairAddress.slice(0, 6) + '...' + pairAddress.slice(38, 42)}
                      </TYPE.main>
                      <CopyHelper toCopy={pairAddress} />
                    </AutoRow>
                  </Column>
                  <Column>
                    <TYPE.main>
                      <RowFixed>
                        <FormattedName text={token0?.symbol ?? ''} maxCharacters={8} />{' '}
                        <span style={{ marginLeft: '4px' }}>Address</span>
                      </RowFixed>
                    </TYPE.main>
                    <AutoRow align="flex-end">
                      <TYPE.main style={{ marginTop: '.5rem' }}>
                        {token0 && token0.id.slice(0, 6) + '...' + token0.id.slice(38, 42)}
                      </TYPE.main>
                      <CopyHelper toCopy={token0?.id} />
                    </AutoRow>
                  </Column>
                  <Column>
                    <TYPE.main>
                      <RowFixed>
                        <FormattedName text={token1?.symbol ?? ''} maxCharacters={8} />{' '}
                        <span style={{ marginLeft: '4px' }}>Address</span>
                      </RowFixed>
                    </TYPE.main>
                    <AutoRow align="flex-end">
                      <TYPE.main style={{ marginTop: '.5rem' }} fontSize={16}>
                        {token1 && token1.id.slice(0, 6) + '...' + token1.id.slice(38, 42)}
                      </TYPE.main>
                      <CopyHelper toCopy={token1?.id} />
                    </AutoRow>
                  </Column>
                  <ButtonLight color={backgroundColor}>
                    <Link color={backgroundColor} external href={'https://snowtrace.io/address/' + pairAddress}>
                      View on the Snowtrace Explorer ↗
                    </Link>
                  </ButtonLight>
                </TokenDetailsLayout>
              </Panel> */}
            </>
          </DashboardWrapper>
        </WarningGrouping>
      </ContentWrapperLarge>
    </PageWrapper>
  )
}

export default withRouter(PairPage)
