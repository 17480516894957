import React, { useEffect } from 'react'
import 'feather-icons'

import TopTokenList from '../components/TokenList'
import { TYPE } from '../Theme'
import Panel from '../components/Panel'
import { useAllTokenData } from '../contexts/TokenData'
import { PageWrapper, FullWrapper } from '../components'
import { RowBetween } from '../components/Row'
import Search from '../components/Search'
import { useMedia } from 'react-use'
import styled from 'styled-components'
import PoolsOverview from '../components/TokenList/TokenPage'

function AllTokensPage() {
  const allTokens = useAllTokenData()

  const HeadingText = styled.h1`
    font-weight: 900;
    font-size: 25px;
    line-height: 27px;
    color: #ffffff;
    text-shadow: 0px 4px 20px #8668df;
    margin-top: 40px;
    margin-bottom: 33px;
  `

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const below600 = useMedia('(max-width: 800px)')

  return (
    <PageWrapper>
      <FullWrapper>
        <PoolsOverview />
        <RowBetween>
          <HeadingText>All Cryptos</HeadingText>
          {/* {!below600 && <Search small={true} />} */}
        </RowBetween>
        <Panel style={{ padding: below600 && '1rem 0 0 0 ' }}>
          <TopTokenList tokens={allTokens} itemMax={50} />
        </Panel>
      </FullWrapper>
    </PageWrapper>
  )
}
export default AllTokensPage
