export const baseColors = {
  failure: 'linear-gradient(82.15deg, #FF2012 16.13%, #FFC633 93.03%)',
  primary: '#1FC7D4',
  white: '#ffffff',
  primaryBright: '#53DEE9',
  primaryDark: '#0098A1',
  secondary: '#7645D9',
  success: '#31D0AA',
  warning: '#FFB237',
  succesGradient: 'linear-gradient(90deg, rgb(6, 211, 230) -0.15%, rgb(255, 219, 13) 96.99%)',
}

export const additionalColors = {
  binance: '#F0B90B',
  overlay: '#452a7a',
  gold: '#FFC700',
  silver: '#B2B2B2',
  bronze: '#E7974D',
}

export const lightColors = {
  ...baseColors,
  ...additionalColors,
  background: '#FFFFFF',
  backgroundDisabled: '#E9EAEB',
  backgroundAlt: '#110733',
  backgroundAlt2: 'rgba(255, 255, 255, 0.7)',
  backgroundTotal: 'rgba(129, 75, 246, 0.2)',
  cardBorder: 'rgba(129, 75, 246, 0.4)',
  cardBorderBlur: 'rgba(129, 75, 246, 0.4)',
  borderBtn: '1px solid rgba(129, 75, 246, 0.2)',
  contrast: '#191326',
  dropdown: '#F6F6F6',
  dropdownDeep: '#EEEEEE',
  invertedContrast: '#FFFFFF',
  input: '#eeeaf4',
  inputSecondary: '#d7caec',
  tertiary: 'rgba(43, 27, 78, 0.4)',
  text: '#FFFFFF',
  grey: '#777E90',
  modal: 'rgba(0, 0, 0, 0.9);',
  textDisabled: '#BDC2C4',
  textSubtle: '#7A6EAA',
  disabled: '#E9EAEB',
  gradients: {
    bubblegum: 'linear-gradient(139.73deg, #E5FDFF 0%, #F3EFFF 100%)',
    inverseBubblegum: 'linear-gradient(139.73deg, #F3EFFF 0%, #E5FDFF 100%)',
    cardHeader: 'linear-gradient(111.68deg, #F2ECF2 0%, #E8F2F6 100%)',
    blue: 'linear-gradient(180deg, #A7E8F1 0%, #94E1F2 100%)',
    violet: 'linear-gradient(180deg, #E2C9FB 0%, #CDB8FA 100%)',
    violetAlt: 'linear-gradient(180deg, #CBD7EF 0%, #9A9FD0 100%)',
    gold: 'linear-gradient(180deg, #FFD800 0%, #FDAB32 100%)',
    success: 'linear-gradient(90deg, #06D3E6 -0.15%, #FFDB0D 96.99%)',
    lottery: 'rgba(129, 75, 246, 0.2)',
  },
  bgButton: '#3B3C4E',
  bgPrimaryButton: 'linear-gradient(90deg, #06D3E6 -0.15%, #FFDB0D 96.99%)',
  backgroundModal: '',
  toggleTurnRight: '',
  defaulltToggle: '',
  textDark: '',
}

export const darkColors = {
  ...baseColors,
  ...additionalColors,
  defaulltToggle: '#110733',
  secondary: '#9A6AFF',
  background: '#08060B',
  backgroundDisabled: '#3c3742',
  backgroundAlt: 'rgba(43, 27, 78, 0.4)',
  backgroundModal: '#110E27',
  toggleTurnRight: '#845FDF',
  backgroundAlt2: 'rgba(39, 38, 44, 0.7)',
  backgroundTotal: 'rgba(129, 75, 246, 0.2)',
  borderBtn: '1px solid rgba(129, 75, 246, 0.2)',
  cardBorder: '#383241',
  cardBorderBlur: 'rgba(129, 75, 246, 0.4)',
  contrast: '#FFFFFF',
  dropdown: '#1E1D20',
  dropdownDeep: '#100C18',
  invertedContrast: '#191326',
  input: '#1b1236',
  inputSecondary: '#262130',
  primaryDark: '#0098A1',
  tertiary: 'rgba(43, 27, 78, 0.4)',
  text: '#F4EEFF',
  textDark: '#000',
  grey: '#777E90',
  modal: 'rgba(0, 0, 0, 0.9)',
  textDisabled: '#666171',
  textSubtle: '#B8ADD2',
  disabled: '#524B63',
  bgButton: '#3B3C4E',
  bgPrimaryButton: 'linear-gradient(90deg, #06D3E6 -0.15%, #FFDB0D 96.99%)',
  bgRedOrangeButton: 'linear-gradient(90.84deg, #FE2900 -3.26%, #FFA500 105.1%)',
  sencondaryLottery: '#C770E6',
  gradients: {
    bubblegum: 'linear-gradient(139.73deg, #313D5C 0%, #3D2A54 100%)',
    inverseBubblegum: 'linear-gradient(139.73deg, #3D2A54 0%, #313D5C 100%)',
    cardHeader: 'linear-gradient(166.77deg, #3B4155 0%, #3A3045 100%)',
    blue: 'linear-gradient(180deg, #00707F 0%, #19778C 100%)',
    violet: 'linear-gradient(180deg, #6C4999 0%, #6D4DB2 100%)',
    violetAlt: 'linear-gradient(180deg, #434575 0%, #66578D 100%)',
    gold: 'linear-gradient(180deg, #FFD800 0%, #FDAB32 100%)',
    success: 'linear-gradient(90deg, #06D3E6 -0.15%, #FFDB0D 96.99%)',
    lottery: 'rgba(129, 75, 246, 0.2)',
  },
}
