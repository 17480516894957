import React from 'react'
import { Flex } from '../Box'
import {
  StyledFooter,
  StyledList,
  StyledListItem,
  StyledSocialLinks,
  StyledSociaSecondlLinks,
  StyledSociaThirdlLinks,
  StyledText,
  TextHighLight,
  WrapFlex,
  WrapTextContact,
  WrapTextSupport,
  InputEmail,
  WrapEmail,
} from './styles'

const MenuItem = ({
  // items,
  // isDark,
  // toggleTheme,
  // currentLang,
  // langs,
  // setLang,
  // cakePriceUsd,
  // buyCakeLabel,
  ...props
}) => {
  return (
    <StyledFooter p={['40px 16px', null, '35px 40px 32px 40px']} {...props} justifyContent="center">
      <WrapFlex width={['100%', null, '1200px;']}>
        <StyledList style={{ paddingLeft: 0 }}>
          <StyledListItem>Contact Us</StyledListItem>
          <img src="/images/gem-uni-footer.png" alt="footer" width="120px" />
          <Flex flexDirection="column" mt={['42px', null, '36px']} alignItems={['center', null, 'baseline']}>
            <WrapTextContact>
              <TextHighLight>For Investment:</TextHighLight>
              <StyledText href="mailto:hello@gemuni.io">hello@gemuni.io</StyledText>
            </WrapTextContact>
            <WrapTextSupport>
              <TextHighLight>For Marketing & Support:</TextHighLight>
              <StyledText href="mailto:growth@gemuni.io">growth@gemuni.io.</StyledText>
            </WrapTextSupport>
          </Flex>
          <Flex flexDirection="column" mt={['42px', null, '20px']}>
            <TextHighLight>Copyright © 2022 GemUni.</TextHighLight>
          </Flex>
        </StyledList>

        <StyledList className="explore" style={{ paddingLeft: 0 }}>
          <StyledListItem>Explore More</StyledListItem>
          <Flex className="wrap-text">
            <StyledText href="https://www.gemuni.io/" target="_blank">
              Home
            </StyledText>
            <StyledText href="https://www.gemuni.io/gaming/casual?page=1" target="_blank">
              Play Games
            </StyledText>
            <StyledText href="https://www.gemuni.io/marketplace/" target="_blank">
              NFT Marketplace
            </StyledText>
            {/* <StyledText>FAQs</StyledText> */}
            <StyledText href="https://gemuni.io/whitepaper" target="_blank">
              Whitepaper
            </StyledText>
            <StyledText href="https://gemuni.io/pitchdeck" target="_blank">
              Pitch Deck
            </StyledText>
            <StyledText href="https://medium.com/@GemUni_Official" target="_blank">
              News
            </StyledText>
            <StyledText href="https://gemuni.gitbook.io/gemuni/resources/faqs" target="_blank">
              FAQs
            </StyledText>
          </Flex>
        </StyledList>

        <StyledList style={{ paddingLeft: 0 }}>
          <StyledListItem>Connect With Us</StyledListItem>
          <StyledSocialLinks />
          <StyledSociaSecondlLinks />
          <StyledSociaThirdlLinks />
        </StyledList>

        <StyledList style={{ paddingLeft: 0 }}>
          <StyledListItem>Join Newsletter</StyledListItem>
          <StyledText>Subscribe to get more latest news and special offers from GemUni</StyledText>
          <WrapEmail>
            <InputEmail placeholder="Enter your email" />
            <img src="/images/send-email.png" alt="email" />
          </WrapEmail>
        </StyledList>
      </WrapFlex>
    </StyledFooter>
  )
}

export default MenuItem
