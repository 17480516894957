import styled from 'styled-components'
import { Box, Flex } from '../Box'
import SocialLinks, { SocialSecondLinks, SocialThirdLinks } from './Components/SocialLinks'

export const breakpointMap = {
  xs: 370,
  sm: 576,
  md: 852,
  lg: 968,
  xl: 1080,
  xxl: 1200,
}
const mediaQueries = {
  xs: `@media screen and (min-width: ${breakpointMap.xs}px)`,
  sm: `@media screen and (min-width: ${breakpointMap.sm}px)`,
  md: `@media screen and (min-width: ${breakpointMap.md}px)`,
  lg: `@media screen and (min-width: ${breakpointMap.lg}px)`,
  xl: `@media screen and (min-width: ${breakpointMap.xl}px)`,
  xxl: `@media screen and (min-width: ${breakpointMap.xxl}px)`,
  nav: `@media screen and (min-width: ${breakpointMap.lg}px)`,
}

export const StyledFooter = styled(Flex)`
  background: linear-gradient(180deg, rgba(17, 14, 39, 0) 0%, #000000 19.27%);
  background-color: #000000;
`

export const StyledList = styled.ul`
  list-style: none;
  margin-bottom: 40px;
  width: -webkit-fill-available;
  text-align: center;
  font-family: 'kanit';

  .wrap-text {
    gap: 1.5rem;
    span {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 26px;
      color: #ffffff;
    }
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    ${mediaQueries.md} {
      display: flex;
      flex-direction: column;
    }
  }

  ${mediaQueries.md} {
    text-align: inherit;
  }
`

export const StyledListItem = styled.li`
  font-size: 16px;
  margin-bottom: 8px;

  &:first-child {
    color: #f8ecb6;
    font-weight: 600;
    margin-bottom: 45px;
    font-size: 22px;
    min-width: 200px;
  }
`

export const StyledIconMobileContainer = styled(Box)`
  margin-bottom: 24px;
`

export const StyledToolsContainer = styled(Flex)`
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-style: solid;
  padding: 24px 0;
  margin-bottom: 24px;

  ${mediaQueries.sm} {
    border-top-width: 0;
    border-bottom-width: 0;
    padding: 0 0;
    margin-bottom: 0;
  }
`

export const StyledSocialLinks = styled(SocialLinks)`
  margin-top: 40px;
  svg {
    height: 40px;
  }
  justify-content: center;

  ${mediaQueries.sm} {
    justify-content: center;
  }
`

export const StyledSociaSecondlLinks = styled(SocialSecondLinks)`
  margin-top: 24px;
  svg {
    height: 40px;
  }
  justify-content: center;

  ${mediaQueries.sm} {
    justify-content: center;
  }
`

export const StyledSociaThirdlLinks = styled(SocialThirdLinks)`
  margin-top: 24px;
  gap: 1.5rem;
  svg {
    height: 40px;
  }
  justify-content: center;

  ${mediaQueries.sm} {
    justify-content: center;
  }
`

export const StyledText = styled.a`
  color: #ffffff;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  &:hover {
    color: #f8ecb6;
  }
`

export const TextHighLight = styled.span`
  color: #938ca7 !important;
`

export const WrapTextContact = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  min-width: max-content;
  /* gap: 8px; */
`

export const WrapTextSupport = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  min-width: max-content;
  /* gap: 8px; */
  margin-top: 1rem;
`

export const WrapFlex = styled(Flex)`
  flex-direction: column;
  align-items: baseline;
  gap: 40px;
  span {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #ffffff;
  }
  ${mediaQueries.md} {
    margin-bottom: 0px;
    flex-direction: row;
    gap: 85px;
  }
`

export const InputEmail = styled.input`
  border: 1px solid rgba(129, 75, 246, 0.2);
  border-radius: 20px;
  padding: 16px;
  background: #120b20;
  min-width: 250px;
  color: #ffffff;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: #938ca7;
    font-family: 'kanit';
  }
  :focus {
    outline: none;
    border: 1px solid #ffffff;
  }
  ${mediaQueries.md} {
    min-width: 250px;
  }
`

export const WrapEmail = styled.div`
  margin-top: 24px;
  position: relative;
  img {
    position: absolute;
    top: 10px;
    right: 38px;
    ${mediaQueries.md} {
      right: 10px;
    }
  }
`
