import React, { useState, useEffect, useMemo, useCallback } from 'react'
import styled from 'styled-components'
import { Box, Flex, Text } from 'rebass'
import TokenLogo from '../TokenLogo'
import { CustomLink } from '../Link'
import Row from '../Row'
import { Divider } from '..'
import { formattedNum, formattedPercent } from '../../utils'
import { useMedia } from 'react-use'
import { withRouter } from 'react-router-dom'
import { OVERVIEW_TOKEN_BLACKLIST } from '../../constants'
import FormattedName from '../FormattedName'
import { TYPE } from '../../Theme'
import { useSavedPairs, useSavedTokens } from 'contexts/LocalStorage'
import { useAllTokenData } from 'contexts/TokenData'

const BoxCard = styled.div`
  div {
    border-radius: 12px;
    min-height: 145px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`
const BoxList = styled.div`
  width: 100%;
  height: 100%;
  overflow: inherit;
  background: rgba(43, 27, 78, 0.4);
  border-radius: 24px;
  border: 1px solid rgba(43, 27, 78, 0.4);
`

const HeadingText = styled.h1`
  font-weight: 900;
  font-size: 25px;
  line-height: 27px;
  color: #ffffff;
  text-shadow: 0px 4px 20px #8668df;
  margin-top: 40px;
  margin-bottom: 33px;
`

const PageButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-block: 26px;
`

const Arrow = styled.div`
  color: ${({ theme }) => theme.primary1};
  opacity: ${(props) => (props.faded ? 0.3 : 1)};
  padding: 0 20px;
  user-select: none;
  :hover {
    cursor: pointer;
    opacity: 0.7;
  }
`

const List = styled(Box)`
  -webkit-overflow-scrolling: touch;
`
const BoxIconSort = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-left: 10px;
`

const DashGrid = styled.div`
  display: grid;
  gap: 30px;
  height: 48px;

  grid-template-columns: 20px 2fr repeat(4, 1fr);
  padding: 0 32px;

  > * {
    justify-content: flex-end;

    &:first-child {
      justify-content: flex-start;
      text-align: left;
      width: 100px;
    }
  }

  > :nth-child(2) {
    justify-content: flex-start;
  }
  @media screen and (max-width: 900px) {
    grid-template-columns: 20px 2fr repeat(3, 1fr);
    & :nth-child(4) {
      display: none;
    }
  }

  @media screen and (max-width: 800px) {
    grid-template-columns: 20px 2fr repeat(2, 1fr);
    & :nth-child(5) {
      display: none;
    }
  }

  @media screen and (max-width: 670px) {
    grid-template-columns: 1fr 1fr;
    padding-left: 0;
    > *:first-child {
      display: none;
    }
  }
`
const DashGridRow = styled.div`
  display: grid;
  gap: 30px;
  /* height: 48px; */

  grid-template-columns: 20px 2fr repeat(4, 1fr);
  padding: 0 32px;
  :hover {
    cursor: pointer;
    opacity: 0.7;
  }

  > * {
    justify-content: flex-end;

    &:first-child {
      justify-content: flex-start;
      text-align: left;
      width: 100px;
    }
  }

  > :nth-child(2) {
    justify-content: flex-start;
  }
  @media screen and (max-width: 900px) {
    grid-template-columns: 20px 2fr repeat(3, 1fr);
    & :nth-child(4) {
      display: none;
    }
  }

  @media screen and (max-width: 800px) {
    grid-template-columns: 20px 2fr repeat(2, 1fr);
    & :nth-child(5) {
      display: none;
    }
  }

  @media screen and (max-width: 670px) {
    grid-template-columns: 1fr 1fr;
    padding-left: 0;
    > *:first-child {
      display: none;
    }
  }
`

const ClickableText = styled(Text)`
  font-family: 'inter';
  font-style: regular;
  font-size: 16px;
  line-height: 24px;
  line-height: 124%;
  color: #777e90;
  display: flex;
  flex-direction: row;
  text-transform: uppercase;
  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
  user-select: none;
  @media screen and (max-width: 640px) {
    font-size: 0.85rem;
  }
`

const DataText = styled(Flex)`
  align-items: center;
  text-align: center;
  color: ${({ theme }) => theme.text1} !important;

  & > * {
    font-size: 14px;
  }

  @media screen and (max-width: 600px) {
    font-size: 12px;
  }
`

const SORT_FIELD = {
  LIQ: 'totalLiquidityUSD',
  VOL: 'oneDayVolumeUSD',
  VOL_UT: 'oneDayVolumeUT',
  SYMBOL: 'symbol',
  NAME: 'name',
  PRICE: 'priceUSD',
  CHANGE: 'priceChangeUSD',
}

// @TODO rework into virtualized list
function TopTokenList({ itemMax = 10, useTracked = false }) {
  // page state
  const [page, setPage] = useState(1)
  const [maxPage, setMaxPage] = useState(1)

  // sorting
  const [sortDirection, setSortDirection] = useState(true)
  const [sortedColumn, setSortedColumn] = useState(SORT_FIELD.VOL)

  const below1080 = useMedia('(max-width: 1080px)')
  const below680 = useMedia('(max-width: 680px)')
  const below600 = useMedia('(max-width: 600px)')

  const [savedPairs, removePair] = useSavedTokens()

  const allPairs = useAllTokenData()
  const favoriteAddress = useMemo(() => {
    return Object.keys(allPairs).length > 0
      ? Object.keys(allPairs)?.filter((token) => Object.keys(savedPairs).indexOf(token) > -1)
      : []
  }, [allPairs, savedPairs])

  const tokens = useMemo(() => {
    return favoriteAddress.length && Object.keys(allPairs).length > 0
      ? favoriteAddress.map((address) => allPairs[address])
      : []
  }, [favoriteAddress, allPairs])
  console.log(tokens, 'tokens')
  const formattedTokens = useMemo(() => {
    return (
      tokens &&
      Object.keys(tokens)
        .filter((key) => {
          return !OVERVIEW_TOKEN_BLACKLIST.includes(key)
        })
        .map((key) => tokens[key])
    )
  }, [tokens])

  useEffect(() => {
    if (tokens && formattedTokens) {
      let extraPages = 1
      if (formattedTokens.length % itemMax === 0) {
        extraPages = 0
      }
      setMaxPage(Math.floor(formattedTokens.length / itemMax) + extraPages)
    }
  }, [tokens, formattedTokens, itemMax])

  const filteredList = useMemo(() => {
    return (
      formattedTokens &&
      formattedTokens
        .sort((a, b) => {
          if (sortedColumn === SORT_FIELD.SYMBOL || sortedColumn === SORT_FIELD.NAME) {
            return a[sortedColumn] > b[sortedColumn] ? (sortDirection ? -1 : 1) * 1 : (sortDirection ? -1 : 1) * -1
          }
          return parseFloat(a[sortedColumn]) > parseFloat(b[sortedColumn])
            ? (sortDirection ? -1 : 1) * 1
            : (sortDirection ? -1 : 1) * -1
        })
        .slice(itemMax * (page - 1), page * itemMax)
    )
  }, [formattedTokens, itemMax, page, sortDirection, sortedColumn])

  const ListItem = ({ item, index }) => {
    return (
      <DashGridRow style={{ paddingBlock: 16 }} focus={true}>
        <DataText area="name" fontWeight="500">
          {index}
        </DataText>
        <Row
          style={{
            paddingLeft: 30,
          }}
        >
          <TokenLogo address={item.id} />
          <CustomLink style={{ marginLeft: '16px', whiteSpace: 'nowrap', color: '#ffffff' }} to={'/token/' + item.id}>
            <FormattedName
              text={below680 ? item.symbol : item.name}
              maxCharacters={below600 ? 8 : 16}
              adjustSize={true}
              link={true}
            />
          </CustomLink>
        </Row>
        {!below1080 && (
          <DataText area="price" color="text" fontWeight="500">
            {formattedNum(item.priceUSD, true)}
          </DataText>
        )}
        {!below1080 && <DataText area="change">{formattedPercent(item.priceChangeUSD)}</DataText>}
        <DataText area="vol">{formattedNum(item.oneDayVolumeUSD, true)}</DataText>
        <DataText area="liq">{formattedNum(item.totalLiquidityUSD, true)}</DataText>
      </DashGridRow>
    )
  }
  const handleSort = useCallback(
    (newField) => {
      setSortedColumn(newField)
      setSortDirection(sortedColumn !== newField ? true : !sortDirection)
    },
    [sortDirection, sortedColumn]
  )
  const IconSort = (dow) => {
    return (
      <>
        {dow ? (
          <BoxIconSort>
            <img src="/icons/up.svg" width="10px" alt="icon sort" height="10px" />
            <img src="/icons/down_blur.svg" width="10px" alt="icon sort" height="10px" style={{ opacity: '0.3' }} />
          </BoxIconSort>
        ) : (
          <BoxIconSort>
            <img src="/icons/up_blur.svg" alt="icon sort" width="10px" height="10px" style={{ opacity: '0.3' }} />
            <img src="/icons/down.svg" alt="icon sort" width="10px" height="10px" />
          </BoxIconSort>
        )}
      </>
    )
  }

  const arrow = useCallback(
    (field) => {
      const directionArrow = !sortDirection ? IconSort(true) : IconSort(false)
      return sortedColumn === field ? directionArrow : ''
    },
    [sortDirection, sortedColumn]
  )
  return (
    <div>
      <HeadingText>Your Watchlist</HeadingText>
      {favoriteAddress.length > 0 ? (
        <>
          <DashGrid center={true} style={{ paddingBottom: 26 }}>
            <Flex alignItems="center" justifyContent="flexStart">
              <span
                style={{
                  color: '#777e90',
                }}
              >
                #
              </span>
            </Flex>
            <Flex
              alignItems="center"
              justifyContent="flexStart"
              style={{
                paddingLeft: 50,
              }}
            >
              <ClickableText
                area="name"
                fontWeight="500"
                textAlign="left"
                onClick={() => {
                  handleSort(SORT_FIELD.NAME)
                }}
              >
                Name {arrow(SORT_FIELD.NAME)}
              </ClickableText>
            </Flex>
            {!below1080 && (
              <Flex alignItems="center">
                <ClickableText
                  area="price"
                  onClick={() => {
                    handleSort(SORT_FIELD.PRICE)
                  }}
                >
                  Price {arrow(SORT_FIELD.PRICE)}
                </ClickableText>
              </Flex>
            )}

            {!below1080 && (
              <Flex alignItems="center">
                <ClickableText
                  area="change"
                  onClick={() => {
                    handleSort(SORT_FIELD.CHANGE)
                  }}
                >
                  Price Change {arrow(SORT_FIELD.CHANGE)}
                </ClickableText>
              </Flex>
            )}

            <Flex alignItems="center">
              <ClickableText
                area="vol"
                onClick={() => {
                  handleSort(SORT_FIELD.VOL)
                }}
              >
                Volume 24h {arrow(SORT_FIELD.VOL)}
              </ClickableText>
            </Flex>

            <Flex alignItems="center">
              <ClickableText
                area="liq"
                onClick={() => {
                  handleSort(SORT_FIELD.LIQ)
                }}
              >
                Liquidity {arrow(SORT_FIELD.LIQ)}
              </ClickableText>
            </Flex>
          </DashGrid>
          <Divider />
          <List p={0}>
            {filteredList &&
              filteredList.map((item, index) => {
                return (
                  <div key={index}>
                    <ListItem key={index} index={(page - 1) * itemMax + index + 1} item={item} />
                    <Divider />
                  </div>
                )
              })}
          </List>
          <PageButtons>
            <div onClick={() => setPage(page === 1 ? page : page - 1)}>
              <Arrow faded={page === 1 ? true : false}>←</Arrow>
            </div>
            <TYPE.body>{'Page ' + page + ' of ' + maxPage}</TYPE.body>
            <div onClick={() => setPage(page === maxPage ? page : page + 1)}>
              <Arrow faded={page === maxPage ? true : false}>→</Arrow>
            </div>
          </PageButtons>
        </>
      ) : (
        <BoxCard>
          <BoxList>
            <Text px="24px" color="grey" py="16px">
              Saved pools will appear here
            </Text>
          </BoxList>
        </BoxCard>
      )}
    </div>
  )
}

export default withRouter(TopTokenList)
