import React, { useEffect } from 'react'
import 'feather-icons'

import { TYPE } from '../Theme'
import Panel from '../components/Panel'
import { useAllPairData } from '../contexts/PairData'
import PairList from '../components/PairList'
import { PageWrapper, FullWrapper } from '../components'
import { RowBetween } from '../components/Row'
import Search from '../components/Search'
import { useMedia } from 'react-use'
import styled from 'styled-components'
import PoolsOverview from '../components/PairList/PairPage'

const HeadingText = styled.h1`
  font-weight: 700;
  font-size: 25px;
  line-height: 27px;
  color: rgb(255, 255, 255);
  text-shadow: rgb(134 104 223) 0px 4px 20px;
  margin-top: 40px;
  margin-bottom: 33px;
`

function AllPairsPage() {
  const allPairs = useAllPairData()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const below800 = useMedia('(max-width: 800px)')

  return (
    <PageWrapper>
      <FullWrapper>
        <PoolsOverview />
        {/* <RowBetween>
          <HeadingText> All Pairs </HeadingText>
           {!below800 && <Search small={true} />} 
        </RowBetween> */}
        <Panel style={{ padding: below800 && '1rem 0 0 0 ' }}>
          <PairList pairs={allPairs} disableLinks={true} maxItems={50} />
        </Panel>
      </FullWrapper>
    </PageWrapper>
  )
}

export default AllPairsPage
